@use '@/scss/underscore' as _;

.countryCodeSelector {
  color: var(--color-type-primary);
  border: none;
  background: none;
  width: auto;
  @include _.flex-row;
  position: relative;
  margin-right: _.unit(1);
  margin-left: _.unit(4);

  > select {
    appearance: none;
    border: none;
    outline: none;
    background: none;
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 0;
  }

  + input {
    padding-left: _.unit(1);
  }
}

:global(body.mobile) {
  .countryCodeSelector {
    font: var(--font-label-1);

    > select option {
      font: var(--font-label-1);
    }

    > svg {
      color: var(--color-brand-default);
      margin-left: _.unit(1);
      width: 16px;
      height: 16px;
    }
  }
}

:global(body.desktop) {
  .countryCodeSelector {
    font: var(--font-body-2);

    > select option {
      font: var(--font-body-2);
    }

    > svg {
      color: var(--color-type-secondary);
      margin-left: _.unit(2);
      width: 20px;
      height: 20px;
    }
  }
}
