@use '@/scss/underscore' as _;

.notification {
  padding: _.unit(3) _.unit(4);
  font: var(--font-body-2);
  color: var(--color-type-primary);
  margin: 0 auto _.unit(2);
  @include _.flex_row;

  .icon {
    width: 20px;
    height: 20px;
    margin-right: _.unit(3);
  }

  &.alert {
    background: var(--color-alert-99);

    .icon {
      color: var(--color-alert-70);
    }
  }

  &.info {
    background: var(--color-neutral-variant-80);

    .icon {
      color: var(--color-neutral-variant-60);
    }
  }
}

.message {
  flex: 1;
  margin-right: _.unit(4);
}

.link {
  max-width: 20%;
}

:global(body.desktop) {
  .notification {
    border-radius: var(--radius);
  }
}
