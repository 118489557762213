@use '@/scss/underscore' as _;
@use '@/scss/colors' as colors;
@use '@/scss/fonts' as fonts;

/* Preview Settings */
.preview {
  pointer-events: none;
  user-select: none;

  main {
    pointer-events: none;
    user-select: none;
  }
}

/* Foundation */
body {
  --radius: 8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  word-break: break-word;
  @include colors.static;

  &.light {
    @include colors.light;
  }

  &.dark {
    @include colors.dark;
  }

  @include fonts.fonts;
}

/* Main Layout */
.container {
  position: absolute;
  inset: 0;
  color: var(--color-type-primary);
  overflow: auto;
  @include _.flex_column(center, normal);
}

.main {
  @include _.flex_column;
}

.placeHolder {
  flex: 1;
}

:global(body.mobile) {
  --max-width: 360px;
  background: var(--color-bg-body);

  .container {
    background: var(--color-bg-body);
    font: var(--font-body-1);
  }

  .main {
    flex: 1;
    align-self: stretch;
    padding: _.unit(4) _.unit(5);
    position: relative;
    background: var(--color-bg-body);
  }
}

:global(body.desktop) {
  --max-width: 400px;
  background: var(--color-bg-float-base);

  .container {
    background: var(--color-bg-float-base);
    font: var(--font-body-2);
  }

  .main {
    width: 640px;
    min-height: 640px;
    position: relative;
    padding: _.unit(6);
    border-radius: 16px;
    background: var(--color-bg-float);
    box-shadow: var(--color-shadow);
  }
}
