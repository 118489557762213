@use '@/scss/underscore' as _;

.socialButton {
  border-radius: 50%;
  @include _.flex-column;
  background: var(--color-bg-layer-2);
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.inverse {
  background: var(--color-type-primary);
}

.icon {
  @include _.image-align-center;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
