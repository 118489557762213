@use '@/scss/underscore' as _;

.content {
  background: var(--color-bg-float-overlay);
  box-shadow: var(--color-shadow);
  border-radius: var(--radius);
  border: _.border(var(--color-line-divider));

  &:focus {
    outline: none;
  }
}

.overlay {
  background: transparent;
  position: fixed;
  inset: 0;
}

.list {
  margin: 0;
  padding: _.unit(1.5) _.unit(1);
}
