@use '@/scss/underscore' as _;

.wrapper {
  @include _.full-page;
}

.container {
  @include _.full-width;
  margin-top: _.unit(2);
}

.title {
  margin-bottom: _.unit(1);
}

.detail {
  margin-bottom: _.unit(6);
  @include _.text-hint;
}

:global(body.mobile) {
  .container {
    margin-top: _.unit(2);
  }

  .title {
    @include _.title;
  }
}

:global(body.desktop) {
  .container {
    margin-top: _.unit(12);
  }

  .title {
    @include _.title-desktop;
  }
}
